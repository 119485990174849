$light-black: #333333;
$black: #212121;
$dark-black: #121212;
$light-purple: #9D57F6;
$purple: #17003C;
$blu: #2339DC;
$green: #8DF98D;
$light-gray: #CED0D8;
$white: #FFFFFF;
$red: #D61E41;
$signarok-red: #702018;
$signarok-yellow: #F0CE7C;
$afarit-green: #637F5E;
$afarit-dark-green: #24362E;
$afarit-blu: #424E57;
$numaya-blu: #131627;
$numaya-gray: #B9C4D1;
$numaya-brown: #312F25;
$tab: #1F2534;

$breakpoints: (
  default: 0px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px
);

$debug-breakpoints: false;

